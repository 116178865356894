/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Button,
  Link,
  SearchIcon,
  StarIcon,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import React from 'react';
import { jsx } from 'theme-ui';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import useFavorites from '@bottlebooks/gatsby-theme-event/src/components/Favorites/useFavorites';
import { ShoppingCartIndicator } from '@bottlebooks/gatsby-theme-event-tasting/src/components/Shop/FirebaseShoppingCartMenu';
import { OrderIndicator } from '@bottlebooks/gatsby-theme-event-tasting/src/components/Shop/FirebaseOrderMenu';
import usePermissions from '@bottlebooks/gatsby-theme-event-tasting/src/usePermissions';

export default function MenuActions({
  searchIsVisible = false,
  toggleSearchIsVisible = (state) => {},

  ...rest
}) {
  const { favorites } = useFavorites();
  const link = useLink();
  const { permissions } = usePermissions();
  return (
    <React.Fragment>
      {!process.env.GATSBY_SKIP_AUTH && (
        <React.Fragment>
          <ShoppingCartIndicator />
          {permissions.includes('manageOrders') && <OrderIndicator />}
          {permissions.includes('manageOrganizers') && (
            <Link to={'/manage'}>
              <Text>Admin</Text>
            </Link>
          )}
        </React.Fragment>
      )}
      {process.env.GATSBY_SKIP_AUTH && (
        <Link
          to={link.favorites()}
          variant="text"
          sx={{ marginRight: 2, position: 'relative' }}
        >
          <StarIcon sx={{ height: '30px', width: '30px' }} />
          {!!favorites.length && (
            <Box
              sx={{
                position: 'absolute',
                left: '25px',
                top: '6px',
                color: 'primary',
                marginTop: '-12px',
              }}
            >
              {favorites.length}
            </Box>
          )}
        </Link>
      )}
      <Button
        variant="text"
        onClick={() => toggleSearchIsVisible(!searchIsVisible)}
        sx={{ marginRight: 2, display: [null, null, 'none'] }}
      >
        <SearchIcon sx={{ height: '30px', width: '30px' }} />
      </Button>
    </React.Fragment>
  );
}
