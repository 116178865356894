/**  @jsx jsx */
import { Box, Flex, Text } from '@bottlebooks/gatsby-theme-base';
import BaseLayout from '@bottlebooks/gatsby-theme-event/src/components/Layout/BaseLayout';
import Footer from '@bottlebooks/gatsby-theme-event/src/components/Layout/Footer';
import FooterLogo from '@bottlebooks/gatsby-theme-event/src/components/Layout/FooterLogo';
import Header from '@bottlebooks/gatsby-theme-event/src/components/Layout/Header';
import MainMenu from '@bottlebooks/gatsby-theme-event/src/components/Layout/MainMenu';
import useEventTheme from '@bottlebooks/gatsby-theme-event/src/components/Layout/useEventTheme';
import { jsx, ThemeProvider } from 'theme-ui';
// import { useScroll } from './useScroll';

export default function Layout({ children, locale, ...rest }) {
  const landingPage = '/'; // useLandingPage({ locale });
  const theme = useEventTheme();

  // Scrolling is currently disabled because it causes significant
  // peformance degredation on Safari. It should only be reactivated
  // once this issue has been resolved.
  // const { showHeader, isScrolledDown } = useScroll();
  const isScrolledDown = false;
  const showHeader = true;
  return (
    <ThemeProvider theme={theme}>
      <BaseLayout {...rest}>
        <Header
          homePath={landingPage}
          isScrolledDown={isScrolledDown}
          showHeader={showHeader}
          locale={locale}
          sx={{ width: '100%', boxShadow: '0 0 15px #d9dde869' }}
        >
          <MainMenu
            locale={locale}
            isScrolledDown={isScrolledDown}
            landingPage={landingPage}
            {...rest}
          />
        </Header>

        {/* <Box
          sx={{
            position: 'absolute',
            left: 3,
            top: ['100px', null, '150px'],
            display: ['none', null, 'block'],
          }}
        >
          <Text sx={{ fontSize: 25 }}>
            <Flex
              sx={{
                width: '40px',
                float: 'left',
                direction: 'row',
                writingMode: 'vertical-rl',
                textOrientation: 'mixed',
                transform: 'rotate(-180deg)',
              }}
            >
              <Box sx={{ color: 'primary' }}>VINI IN DEGUSTAZIONE</Box>
              <Box sx={{ color: 'accent', margin: '5px 0px 5px 0px' }}> / </Box>
              <Box sx={{ color: 'accent' }}>WINES FOR TASTING</Box>
            </Flex>
          </Text>
        </Box> */}
        {/* <Box sx={{ marginLeft: [null, null, 5], ...rest }}>{children}</Box> */}
        <Box>{children}</Box>
        <Footer sx={{ marginTop: 'auto' }}>
          <FooterLogo to={landingPage} />
        </Footer>
      </BaseLayout>
    </ThemeProvider>
  );
}
