import { graphql } from 'gatsby';
import { matchSorter, rankings } from 'match-sorter';
import React from 'react';

const getProductFullName = ({ shortName, vintage }) =>
  [shortName, vintage].filter(Boolean).join(' ').trim();

const getProductNameWithProducer = ({ shortName, vintage, producer }) =>
  [producer?.name, shortName, vintage].filter(Boolean).join(' ').trim();

const getProductVarieties = ({ grapeVarieties }) =>
  grapeVarieties?.map(({ varietyName }) => varietyName);

export default function useProductSearch(items, query) {
  return React.useMemo(() => {
    if (!query) return items;
    return matchSorter(items, query, {
      keys: [
        { key: getProductFullName, threshold: rankings.MATCHES },
        'producer.name',
        'productNumber',
        { key: 'vintage', threshold: rankings.EQUAL },
        'regionName',
        'classification',
        getProductVarieties,
        getProductNameWithProducer,
      ],
      threshold: rankings.ACRONYM,
      // This baseSort function will use the original index of items as the tie breaker
      baseSort: (a, b) => {
        return a.index < b.index ? -1 : 1;
      },
    });
  }, [items, query]);
}

export const fragment = graphql`
  fragment Search_Product on Product {
    __typename
    grapeVarieties {
      varietyName
    }
    classification
    specialClassification
    shortName
    vintage(removeNonVintage: true)
    producer {
      name
    }
  }
  fragment bb_Search_Product on Bottlebooks_Product {
    regionName
  }
`;
