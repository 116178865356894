/** @jsx jsx */
import {
  Box,
  Button,
  CloseIcon,
  Container,
  Flex,
  HamburgerIcon,
} from '@bottlebooks/gatsby-theme-base';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useState } from 'react';
import { jsx } from 'theme-ui';
import useSnipcart from '@bottlebooks/gatsby-theme-event/src/useSnipcart';
import useFavorites from '@bottlebooks/gatsby-theme-event/src/components/Favorites/useFavorites';
import { ShoppingCartIndicator } from '@bottlebooks/gatsby-theme-event/src/components/Shop/ShoppingCartMenu';
import SiteSearch from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/SiteSearch';
import Logo from '@bottlebooks/gatsby-theme-event/src/components/Layout/Logo';
import MenuActions from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuActions';
import MenuItem from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuItem';

/**
 * @param {object} opt
 * @param {object[]} opt.menuItems
 * @param {string} opt.landingPage
 * @param {boolean} opt.isScrolledDown
 * @param {string} opt.locale
 * @returns
 */
export default function MainMenuMobile({
  menuItems,
  landingPage,
  isScrolledDown,
  locale,
  ...rest
}) {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [searchIsVisible, toggleSearchIsVisible] = useState(false);

  return (
    <Container {...rest}>
      <Flex>
        <Flex direction="column" sx={{ width: '100%' }}>
          <Flex align="center">
            <Box>
              <Button
                variant="text"
                onClick={() => setMenuIsVisible(!menuIsVisible)}
              >
                {menuIsVisible ? <CloseIcon /> : <HamburgerIcon />}
              </Button>
            </Box>
            <Box sx={{ marginLeft: 3 }}>
              <Logo to={landingPage} isScrolledDown={isScrolledDown} />
            </Box>
            <Flex
              direction="row-reverse"
              align="center"
              sx={{ flexGrow: 1 }}
              {...rest}
            >
              <MenuActions
                searchIsVisible={searchIsVisible}
                toggleSearchIsVisible={toggleSearchIsVisible}
                {...rest}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {searchIsVisible && (
        <Search
          locale={locale}
          onClick={() => toggleSearchIsVisible(!searchIsVisible)}
        />
      )}
      {menuIsVisible && (
        <Menu
          menuItems={menuItems}
          onClick={() => setMenuIsVisible(!menuIsVisible)}
        />
      )}
    </Container>
  );
}

function Menu({ onClick, menuItems }) {
  const { i18n } = useLingui();
  const { favorites } = useFavorites();
  const { openCart } = useSnipcart();
  return (
    <Box
      sx={{
        position: 'fixed',
        paddingX: 2, // Match header padding
        paddingBottom: 3,
        zIndex: 'modal',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'light',
        overflowY: 'scroll',
      }}
    >
      <Flex direction="column">
        <Button
          variant="text"
          onClick={onClick}
          sx={{
            marginRight: 'auto',
            marginTop: 2.5,
            marginLeft: 2,
          }}
        >
          <CloseIcon />
        </Button>
        <Flex
          direction="column"
          sx={{
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              target={index < 2 ? '_blank' : ''}
              {...item}
            />
          ))}
          {/* <MenuItem
            type="favorites"
            name={i18n._(t`Favorites`)}
            counter={favorites.length}
          /> */}
          {process.env.GATSBY_SNIPCART_API_KEY && (
            <MenuItem
              onClick={() => openCart()}
              // Conflict between menu and text variant requires manual override
              sx={{
                padding: 2,
                border: 0,
                backgroundColor: 'transparent',
              }}
            >
              <ShoppingCartIndicator variant="small">
                <Trans>Cart</Trans>
              </ShoppingCartIndicator>
            </MenuItem>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

function Search({ onClick, locale }) {
  const { i18n } = useLingui();
  return (
    <Box
      sx={{
        position: 'fixed',
        paddingBottom: 3,
        zIndex: 'modal',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'light',
        overflowY: 'scroll',
      }}
    >
      {/* // Match header padding */}
      <Flex
        sx={{
          backgroundColor: 'background',
          paddingX: 2,
          paddingBottom: 2,
        }}
      >
        <Box>
          <Button
            variant="text"
            onClick={onClick}
            sx={{
              marginRight: 'auto',
              marginTop: 2.5,
              marginLeft: 2,
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <SiteSearch
            variant="large"
            locale={locale}
            placeholder={i18n._(t`Search for producer or wine…`)}
            sx={{ marginX: 2, marginTop: 2 }}
          />
        </Box>
      </Flex>
    </Box>
  );
}
