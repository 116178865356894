/** @jsx jsx */
import { Box, Container, Flex } from '@bottlebooks/gatsby-theme-base';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { jsx } from 'theme-ui';
import SiteSearch from '@bottlebooks/gatsby-theme-event/src/components/SiteSearch/SiteSearch';
import Logo from '@bottlebooks/gatsby-theme-event/src/components/Layout/Logo';
import MenuItem from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuItem';
import MenuActions from '@bottlebooks/gatsby-theme-event/src/components/Layout/MenuActions';

/**
 * @param {object} opt
 * @param {object[]} opt.menuItems
 * @param {string} opt.landingPage
 * @param {boolean} opt.isScrolledDown
 * @param {string} opt.locale
 * @returns
 */
export default function MainMenuDesktop({
  menuItems,
  landingPage,
  isScrolledDown,
  locale,
  ...rest
}) {
  const { i18n } = useLingui();
  return (
    <Container fluid sx={{ maxWidth: 'container.fluid' }} {...rest}>
      <Flex align="center">
        <Flex direction="column" sx={{ flexGrow: 1 }}>
          <Flex align="center" sx={{ height: '64px' }}>
            <Logo sx={{ marginRight: 3 }} />
            <Box sx={{ position: 'relative' }}>
              <SiteSearch
                variant="large"
                locale={locale}
                placeholder={i18n._(t`Search for producer or wine…`)}
                sx={{ width: '300px', marginLeft: 2 }}
              />
            </Box>
            <Flex
              direction="row-reverse"
              align="center"
              sx={{ flexGrow: 1 }}
              {...rest}
            >
              <MenuActions {...rest} />
            </Flex>
          </Flex>
          <Flex></Flex>
          <Flex align="center" sx={{ height: '64px' }}>
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                {...item}
                sx={{ width: ['48%', '48%', 'initial', 'initial'] }}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
}
